import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
// Style Imports
import Layout from "../components/Layout/Layout";
import "./404.scss";

export default function NotFound({ data }) {
  const notFoundData = {
    ...data.allContentfulWebPages.edges[0].node.pages[5].pageLayout
      .layoutMain[0],
  };

  const [error, setError] = useState(null);
  useEffect(() => {
    try {
      if (!data) {
        throw new Error("Data not available");
      }
    } catch (error) {
      setError(error);
    }
  }, [data]);

  if (error) {
    return <span>{error.map((err) => err.message).join(",")}</span>;
  }

  return (
    <Layout
      pageTitle={"404"}
      navData={
        data.allContentfulWebPages.edges[0].node.pages[4].pageLayout.navigation
      }
      footerData={
        data.allContentfulWebPages.edges[0].node.pages[0].pageLayout.footer
      }
    >
      <div className="error-page">
        <div className="vector-container">
          <img
            src={`https:${notFoundData.errorPageImages[1].file.url}`}
            alt={notFoundData.errorPageImages[1].description}
            className="vector1"
            loading="lazy"
          />
        </div>
        <div className="error-page-main">
          <img
            src={`https:${notFoundData.errorPageImages[0].file.url}`}
            alt={notFoundData.errorPageImages[0].description}
            loading="lazy"
          />
          <div className="error-page-content">
            <p className={`text-0`}>{notFoundData.errorPageText[0]}</p>
            <p className={`text-1`}>{notFoundData.errorPageText[1]}</p>
            <p className={`text-2`}>{notFoundData.errorPageText[2]}</p>
            <Link
              to={notFoundData.errorPageButton.buttonLink.pageSlug}
              className="btn-primary"
            >
              {notFoundData.errorPageButton.buttonText}
            </Link>
          </div>
        </div>
        <div className="vector-container">
          <img
            src={`https:${notFoundData.errorPageImages[2].file.url}`}
            alt={notFoundData.errorPageImages[2].description}
            className="vector2"
            loading="lazy"
          />
          <img
            src={`https:${notFoundData.errorPageImages[3].file.url}`}
            alt={notFoundData.errorPageImages[3].description}
            className="vector3"
            loading="lazy"
          />
        </div>
      </div>
    </Layout>
  );
}

export const pageNotFoundData = graphql`
  query PageNotFoundQuery {
    allContentfulWebPages {
      edges {
        node {
          pages {
            pageLayout {
              navigation {
                navigationPages {
                  pageTitle
                  pageSlug
                  pageLayout {
                    layoutMain {
                      ... on ContentfulContainer {
                        containerItems {
                          ... on ContentfulCategoryTeaser {
                            category {
                              categoryInnerTitle
                              categorySlug
                              categoryProducts {
                                product {
                                  productInnerTitle
                                  productSlug
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                navigationImages {
                  file {
                    url
                  }
                  description
                }
                navigationMediaIcons {
                  socialMediaIcon {
                    file {
                      url
                    }
                    description
                  }
                  socialMediaTitle
                  socialMediaLink
                }
              }
              layoutMain {
                ... on Contentful404PageContent {
                  errorPageText
                  errorPageImages {
                    description
                    file {
                      url
                    }
                  }
                  errorPageButton {
                    buttonText
                    buttonLink {
                      ... on ContentfulPage {
                        pageSlug
                      }
                    }
                  }
                }
              }
              footer {
                subscribeText
                subscribeDescription
                footerButtonText
                blockTitleFollowUs
                socialMediaLogosFooter {
                  socialMediaIcon {
                    file {
                      url
                    }
                  }
                  socialMediaLink
                }
                followUsDescription
                blockTitleAddress
                blockInfoAddress
                blockTitleCallUs
                blockInfoCallUs
                blockTitleWriteUs
                blockInfoWriteUs
                blockTitleExplore
                footerPages {
                  pageTitle
                  pageSlug
                }
                copyrightText
                googleMapsAddress {
                  googleMapsAddress
                }
              }
            }
          }
        }
      }
    }
  }
`;
